import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tokenexpired-popup',
  templateUrl: './tokenexpired-popup.component.html',
  styleUrls: ['./tokenexpired-popup.component.scss']
})
export class TokenexpiredPopupComponent {

  constructor(
    public modal: NgbActiveModal,
    private router: Router
  ) {}
  navigateToEAudit(): void {
    this.modal.close();
    this.router.navigate(['docintake/eAudit']);
  }
 }
