import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private baseUrl = environment.tokenUrl;
  private tokens: { [key: string]: string | null } = {
    validationService: null,
    uploadService: null,
    sampleSpringBootService: null,
  };

  constructor(private http: HttpClient) {}

  async fetchToken(erNumber: any, caseid: any): Promise<string> {
    try {
      const url = this.baseUrl + `?erNumber=${erNumber}&caseID=${caseid}`;
      const response = await this.http
        .get(url, { responseType: 'text' })
        .toPromise();
      sessionStorage.setItem('token', response);
      return response;
    } catch (error) {
      console.error(`Error fetching token for`, error);
      return Promise.reject(''); 
    }
  }

  clearToken(service: string): void {
    this.tokens[service] = null;
  }
}
