import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent {
  @Input() dataToTakeAsInput: any;
  @Input() fileToDelete:any;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  confirm() {
   
    this.activeModal.close(true);
  }
  cancel() {
   
    this.activeModal.close(false);
  }
}
