import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, mergeMap } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) { }

  //private readonly excludedUrls = ['https://proxy-spring-boot-gmboqmwosa-uk.a.run.app/docprocessor/api/v1/docintake-proxy/get-id-token'];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = sessionStorage.getItem('token');
    console.log('tooo', token);

    if (token) {
      const cloned = req.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + `${token}`

        },
      });

      return next.handle(cloned);
    }
    else {
      return next.handle(req)
    }

  }


}
