<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>
<div class="register-form">
  <h3 class="header">eAudit Submission Form</h3>

  <div class="alert alert-danger" role="alert" *ngIf="errorSubmitMsg.length > 0"> <i
      class="bi-exclamation-octagon-fill danger-icon-style"></i> {{errorSubmitMsg}}

  </div>

  <div class="alert alert-info" *ngIf="messageFromSampleService.length > 0"> 
    <p>{{messageFromSampleService}}</p>
  </div>
  
  <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-group">
      <label class= "width-100" i18n="ER Header|Title for the ER Header@@ERHeader">ER Number
      <input type="text" formControlName="erNumber" name="erNumber" class="form-control" placeholder="Enter ER Number"
        minlength="7" maxlength="7" (keydown)="allowNumbers($event)" required 
        oninput="this.value=this.value.slice(0,this.maxLength)"  oninput="this.value = this.value.replace(/\D+/g, '')" 
        />
      </label>
      <div style='padding-top : 10px'> </div>
    
      <div *ngIf="erNumber.invalid && (erNumber.dirty || erNumber.touched) " class="alert alert-danger">
          <div *ngIf="erNumber.errors?.['required']"  > 
              ER Number is required.
          </div>
          <div *ngIf="erNumber.errors?.['minlength'] || erNumber.errors?.['maxlength']">
            ER Number must be a 7-digit number.
          </div>
      </div>
    </div>

    <div class="form-group">
      <label class= "width-100" i18n="Case ID Header|Title for the Case ID Header@@caseIdHeader">Case ID
      <input type="text" formControlName="caseId" name="caseId" class="form-control" placeholder="Enter Case ID"
       minlength="16" maxlength="16" oninput="this.value = this.value.replace(/\D+/g, '')" 
         (keydown)="allowNumbers($event)"  required />
        </label>
        <div style='padding-top : 10px'> </div> 
      <div *ngIf="caseId.invalid && (caseId.dirty || caseId.touched) " class="alert alert-danger">
        <div *ngIf="caseId.errors?.['required']"  > 
          Case ID is required.
      </div> 
        <div *ngIf="caseId.errors?.['minlength'] || caseId.errors?.['maxlength']">
          Case ID must be a 16-digit number.
        </div>
    </div>

    </div>
    
    <div *ngIf="reCAPTCHAVisible"> reCAPTCHA Token <br />
      <p>{{reCAPTCHAToken}}</p>
    </div>

    <div class="form-group">
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid || recaptchaError">Submit</button>
      <button type="button" (click)="onReset()" class="btn btn-warning float-right">
        Reset
      </button>
    </div>
  </form>
</div>