<div class="alert alert-success" role="alert">
  <p class="subHead">
    <i class="bi bi-check-circle-fill icon-style"></i>Thank You for uploading
    documents to New York State Department of Labor Unemployment Insurance
    Employer Services Audit Unit. Confirmation Number:
    {{ confimationNumber}}
  </p>
  <button type="submit" class="btn btn-primary btn-lg"  style="
  margin-left: 620px;
  margin-top: 32px;"
  (click)="goBack()">
  <i class="bi bi-house">
  Go Back
 </i>
</button>
</div>
