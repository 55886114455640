<div class="modal-header">
    </div>
    <div class="modal-body">
    <p>Your session has expired. Please enter your ER number and Case Id again.</p>
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="navigateToEAudit()" style="
    margin-right: 204px;
">Go Back</button>
    </div>
